:root {
  --mdc-theme-primary: #474747 !important;
  --mdc-theme-secondary: #feeae6 !important;
}

.xr-button-overlay{
  display: none;
}

body{
  display: block;
  padding: 0;
  margin: 0;
  background-color: hsla(0, 0%, 12%, 1);
}
input[type='file'] {
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}
#drop_zone {
  min-height: 10em;
  background: #eee;
  margin:auto; 
  z-index: 3;
}
#videoPopup {
  z-index: 33;
}
#animationControls{
  display: flex !important; 
  top: 80vh;
  width: auto;
  z-index: 3;
  padding-left: 20px;
  justify-content:center;
  align-items: center;
  color: aqua !important;
}
.hide{
  display: none;
  visibility: hidden;
  opacity: 0;
}


canvas {
  top: 0;
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
}

.padding{
  padding: 10px;
}

#company-logo{
  position: absolute;
  bottom: 0;
  padding: 2px;
  margin: 5px;
  z-index: 1;
}

#loadingScreen {
  background-color: hsla(0, 0%, 12%, 1);
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 11;
  font-family: Arial, Helvetica, sans-serif;
  pointer-events: none;
  color: aliceblue;
}
#homeScreen {
  background-color: rgba(31, 31, 31, 0);
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;
  font-family: Arial, Helvetica, sans-serif;
  pointer-events: none;
  color: aliceblue;
}
#homeScreenBackground {
  background-color: rgba(31, 31, 31, 0.5);
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 10;
}
#homeScreenText{
  padding: 15px;
  text-align: center;
}
#homeScreenholder{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 40vh;
}

#overlaidInstructions {
  background-color: rgba(31, 31, 31, 0);
  position: absolute;
  width: 100vw;
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 8;
  font-family: Arial, Helvetica, sans-serif;
  pointer-events: none;
  color: aliceblue;
}
#overlaidInstructionsText{
  padding: 30px;
  text-align: center;
}
#overlaidInstructionsholder{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 40vh;
}

#rotatePromptContainer {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9;
  pointer-events: none;
  color: aliceblue;
}
#rotatePrompt {
  font-size: 48px;
  margin-top: 30vh;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.527));
}

#bottomBar{
  position:fixed;
  height: 45px;
  z-index: 10;
  bottom: 0;
  background-color: hsla(0, 0%, 8%, 1) ;
  width: 100%;
  display: flex;
  justify-content: center;
}

.switch-margin{
  margin: 15px;
  right: 0;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 75px;
  height: 50px;
  margin-top: 100px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.blur {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
  width: 100px;
  height: 100px;
  background-color: #ccc;
  pointer-events: none;

}
.hotspot {
  transform: translate(-50%, -50%);
  position: relative;
  width: 40px;
  height: 40px;
  border: none;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, .7);
  border: 2px solid #333333;
  background-size:cover;
  background-repeat: no-repeat;
  cursor: pointer;
}
.hotspot:hover {
  -webkit-animation: pulse 1s cubic-bezier(0.10, 0, 0, 1);
  -moz-animation: pulse 1s  cubic-bezier(0.10, 0, 0, 1);
  -ms-animation: pulse 1s cubic-bezier(0.10, 0, 0, 1);
  animation: pulse 1s cubic-bezier(0.10, 0, 0, 1);
}

@-webkit-keyframes pulse {to {box-shadow: 0 0 0 12px rgba(45, 45, 45, 0);}}
@-moz-keyframes pulse {to {box-shadow: 0 0 0 12px rgba(45, 45, 45, 0);}}
@-ms-keyframes pulse {to {box-shadow: 0 0 0 12px rgba(45, 45, 45, 0);}}
@keyframes pulse {to {box-shadow: 0 0 0 12px rgba(45, 45, 45, 0);}}